<template>
  <div class="versionmanagement">
    <div class="users_form">
      <el-button type="primary" @click="versionmanagementadd">添加</el-button>
    </div>
    <el-table :data="list" :border="true" class="table" v-loading="tableLoading">
      <el-table-column
        label="序號"
        prop="id"
        width="50"
        align="center"
      ></el-table-column>

      <el-table-column label="版本名稱" prop="version_name"></el-table-column>
      <el-table-column
        label="有效天數"
        prop="valid_time"
        width="150"
        :show-overflow-tooltip="true"
      >
      </el-table-column>

      <el-table-column label="操作" width="300">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="info"
            icon="el-icon-edit"
            @click="compilexiugai(scope.row.id)"
            >編輯</el-button
          >
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="open(scope.row.id)"
            >刪除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加 -->
    <el-dialog
      title="添加版本"
      class="dialog"
      :visible.sync="dialogVisible"
      width="400px"
      :before-close="handleClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="版本名稱" prop="version_name">
          <el-input v-model="ruleForm.version_name" placeholder="請輸入版本名稱"></el-input>
        </el-form-item>
        <el-form-item label="天數" prop="valid_time">
          <div class="form_item">
            <el-button class="btn" @click="subtract">-</el-button>
            <el-input class="ipt" v-model="ruleForm.valid_time"></el-input>
            <el-button class="bon" @click="plus">+</el-button>
          </div>
        </el-form-item>
        <el-form-item label="版本介紹" prop="version_remark">
          <el-input
            type="textarea"
            v-model="ruleForm.version_remark"
            placeholder="請輸入版本介紹"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="versionsadd">確 定</el-button>
      </span>
    </el-dialog>
    <!-- 編輯 -->
    <el-dialog
      title="編輯版本"
      class="dialog"
      :visible.sync="dialog"
      width="400px"
      :before-close="Close"
    >
      <el-form
        :model="Info"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="版本名稱" prop="version_name">
          <el-input v-model="Info.version_name" placeholder="請輸入版本名稱"></el-input>
        </el-form-item>
        <el-form-item label="天數" prop="valid_time">
          <div class="form_item">
            <el-button class="btn" @click="subtracts">-</el-button>
            <el-input class="ipt" v-model="Info.valid_time"></el-input>
            <el-button class="bon" @click="pluss">+</el-button>
          </div>
        </el-form-item>
        <el-form-item label="版本介紹" prop="version_remark">
          <el-input type="textarea" v-model="Info.version_remark" placeholder="請輸入版本介紹"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="versionsquedi">確 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableLoading: false,
      list: [],
      ruleForm: {
        id: "",
        version_name: "",
        valid_time: 0,
        version_remark: "",
      },
      ruleForms: {
        id: "",
        version_name: "",
        valid_time: 0,
        version_remark: "",
      },
      rules: {
        version_name: [
          { required: true, message: "請輸入版本名稱", trigger: "blur" },
        ],
        valid_time: [
          { required: true, message: "請輸入天數", trigger: "blur" },
        ],
        version_remark: [
          { required: true, message: "請輸入版本簡介", trigger: "blur" },
        ],
      },
      dialogVisible: false,
      dialog: false,
      opens: {
        id: "",
        is_del: 1,
      },
      Info: {},
    };
  },
  methods: {
    async getversionmanagement() {
      this.tableLoading = true
      const res = await this.$api.getversionmanagement();
      this.tableLoading = false
      this.list = res.data.data;
    },
    versionmanagementadd() {
      this.dialogVisible = true;
      this.empty()
    },
    // 添加
    async versionsadd() {
      const res = await this.$api.versionsadd(this.ruleForm);
      if (res.data.code !== 200) {
        this.$message.error(res.data.msg);
      } else {
        this.$message.success('添加成功');
        this.getversionmanagement();
        this.dialogVisible = false;
      }
    },
    subtract() {
      // this.ruleForm.valid_time--;
      if (this.ruleForm.valid_time == 0) {
        return;
      }
      this.ruleForm.valid_time--;
    },
    plus() {
      this.ruleForm.valid_time++;
      console.log(1111);
    },

    subtracts() {
      if (this.Info.valid_time == 0) {
        return;
      }
      this.Info.valid_time--;
    },
    pluss() {
      this.Info.valid_time++;
    },
    // 刪除
    async open(id) {
      this.opens.id = id;
      const ress = await this.$confirm(
        "此操作將永久刪除該用戶, 是否繼續?",
        "提示",
        {
          confirmButtonText: "確定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (ress !== "confirm") {
        return this.$message.info("取消刪除");
      }
      await this.$api.deletesopen(this.opens);
      if(res.data.code == 200) {
        this.getversionmanagement();
        this.$message.success("刪除成功");
      } else {
        this.$message.error('刪除失敗')
      }
    },
    // 編輯
    async compilexiugai(id) {
      this.dialog = true;
      const res = await this.$api.compilexiugai({ id: id });
      this.Info = res.data.data;
      console.log(this.Info);
    },
    // 編輯確定
    async versionsquedi() {
      this.ruleForms = this.Info;
      const res = await this.$api.versionsquedi(this.ruleForms);
      if (res.data.code !== 200) {
        this.$message.error(res.data.msg);
      } else {
        this.$message.success('修改成功');
        this.getversionmanagement();
        this.dialog = false;
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
    Close() {
      this.dialog = false;
    },

    empty (){
      this.ruleForm={
        id: "",
        version_name: "",
        valid_time: 0,
        version_remark: "",
      }
    }
  },
  created() {
    this.getversionmanagement();
  },
};
</script>

<style lang="scss" scoped>
.versionmanagement {
  .users_form {
    padding: 5px 0px;
    display: flex;
    align-items: center;

    .el-form {
      width: 300px;
      height: 40px;
    }

    .el-button {
      margin: 0px 10px;
    }
  }
  .dialog {
    .el-dialog__body {
      .el-form {
        .el-form-item {
          .form_item {
            display: flex;
            .ipt {
              width: 58%;

              ::v-deep.el-input__inner {
                border-radius: 0;
                text-align: center;
              }
            }
            ::v-deep.el-button {
              border-radius: 0;
              background-color: #dcdfe6;
            }
            .btn {
              border-right: 0;
            }
            .bon {
              border-left: 0;
            }
          }
        }
      }
    }
  }
}
</style>